import { Button, Popconfirm, message, Input, Radio, Select, Upload, Checkbox, InputNumber, Dropdown, Menu } from "antd";
import { FaStar, FaListUl, FaFileUpload, FaEnvelope, FaTextHeight } from 'react-icons/fa';
import { observer } from "mobx-react-lite";
import React from "react";
import { RiSearchLine } from "react-icons/ri";
import { Buttons, Drawers, Forms, Spinners } from "../../../components";
import { Store } from "../../../stores/stores";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import "./style.css";
import { SurveyStore } from "../../../stores/survey";
import { ISurvey, ISurveyQuestion, ISurveyQuestionType } from "../../../utils/interfaces";
import { SurveyQuestionTypeStore } from "../../../stores/survey-type-question";
import { TypeId } from "../../../utils/enums";
import { SurveyQuestionStore } from "../../../stores/survey-question";
import QuestionModal from "../../../components/Modals/QuestionModal";
import { FieldNumberOutlined, UploadOutlined, PlusOutlined } from "@ant-design/icons";
import { StandardFields, OptionalFields } from "../../../utils/constant";

export const Builder = observer(() => {
    const [value, setValue] = React.useState(1);
    const [selectedClosingMsg, setSelectedClosingMsg] = React.useState('');
    const [selectedOpeningMsg, setSelectedOpeningMsg] = React.useState('');
    const [currentQuestion, setCurrentQuestion] = React.useState<ISurveyQuestion>();
    const [surveyLocalQuestion, setSurveyLocalQuestion] = React.useState<ISurveyQuestion[]>([]);
    const [, setSurveyLocalQuestionType] = React.useState<ISurveyQuestionType[]>([]);
    const [surveyText, setSurveyText] = React.useState<ISurvey[]>([]);
    const [questionModalVisible, setQuestionModalVisible] = React.useState(false);
    const [selectedValue, setSelectedValue] = React.useState(0);
    const [optionalFields, setOptionalFields] = React.useState<string[]>([]);

    const { surveyStore, surveyQuestionType, surveyQuestion } = Store;
    const { Option } = Select;

    React.useEffect(() => {
        if (!surveyStore.surveyLoaded && !surveyStore.isLoading) {
            surveyStore.loadSurvey();
        }
    }, [surveyStore]);

    React.useEffect(() => {
        setSurveyLocalQuestion(surveyQuestion.surveyQuestions.value.data.slice());
    }, [surveyQuestion.surveyQuestions.value.data]);

    React.useEffect(() => {
        setSurveyLocalQuestionType(surveyQuestionType.hrQuestionTypes.value.data);
    }, [surveyQuestionType.hrQuestionTypes.value.data]);

    React.useEffect(() => {
        setSurveyText(surveyStore.hrSurveys.value.data);
    }, [surveyStore.hrSurveys.value.data]);

    React.useEffect(() => {
        if (surveyStore.currentSurvey) {
            surveyQuestion.setSurveyQuestions(surveyStore.currentSurvey.surveyQuestions);
            setSelectedClosingMsg(surveyStore.currentSurvey.closingMessage);
            setSelectedOpeningMsg(surveyStore.currentSurvey.openingMessage);
        }
    }, [surveyStore.currentSurvey, surveyQuestion]);

    React.useEffect(() => {
        if (surveyStore.currentSurvey) {
            const index = surveyText.findIndex((x) => x.id === surveyStore.currentSurvey?.id);
            if (index >= 0) {
                setSelectedValue(index);
            }
        }
    }, [surveyStore.currentSurvey, surveyText]);

    React.useEffect(() => {
        surveyQuestionType.loadQuestionTypes();
    }, [surveyQuestionType]);

    const onRadioChange = (e: any) => {
        setValue(e.target.value);
    };

    const showQuestionModal = (q: any) => {
        setCurrentQuestion(q);
        setQuestionModalVisible(true);
    };

    const addNewQuestion = async (type_id: TypeId) => {
        if (surveyStore.currentSurvey) {
            let qcontent;
            let options: string[] | undefined;

            switch (type_id) {
                case TypeId.Text:
                    qcontent = { title: "Ask Question here" };
                    break;
                case TypeId.MultipleChoice:
                case TypeId.MultiSelect:
                    qcontent = { title: "Ask Multiple Question here" };
                    options = [];
                    break;
                case TypeId.Email:
                    qcontent = { title: "New Email Question" };
                    break;
                case TypeId.Document:
                    qcontent = { title: "New Document Question" };
                    break;
                case TypeId.Number:
                    qcontent = { title: "New Number Question" };
                    break;
                default:
                    console.error('Invalid type_id');
                    return;
            }

            let question: ISurveyQuestion = {
                questionTypeId: type_id,
                text: qcontent.title,
                surveyId: surveyStore.currentSurvey.id,
                position: surveyQuestion.surveyQuestions.value.data.length,
                options: options
            } as ISurveyQuestion;

            surveyQuestion.addNewSurveyQuestion(question);
            await surveyStore.loadSurvey();
        } else {
            message.warning("Please select a survey");
        }
    };

    const getIconComponent = (type_id: TypeId) => {
        switch (type_id) {
            case TypeId.Text:
                return <FaTextHeight />;
            case TypeId.MultipleChoice:
            case TypeId.MultiSelect:
                return <FaListUl />;
            case TypeId.Email:
                return <FaEnvelope />;
            case TypeId.Document:
                return <FaFileUpload />;
            case TypeId.Number:
                return <FieldNumberOutlined />;
            default:
                console.error('Invalid type_id');
                return null;
        }
    };

    const sortQuestion = (res: any) => {
        const items = Array.from(surveyQuestion.surveyQuestions.value.data);
        const [sortedItems] = items.splice(res.source.index, 1);
        items.splice(res.destination.index, 0, sortedItems);
        items.map((q, inx) => {
            q.position = inx;
            surveyQuestion.updateSurveyQuestion(q.id, q);
        });
        surveyQuestion.setSurveyQuestions(items);
    };

    const renderElement = (q: { questionTypeId: string; text: string | null; options: string[] | undefined; }) => {
        if (q.questionTypeId === TypeId.Text) {
            return <Input />;
        } else if (q.questionTypeId === TypeId.MultipleChoice) {
            return (
                <Radio.Group onChange={onRadioChange} className='multi-choice' value={value}>
                    {q.options?.map((m: any, inx: any) => (
                        <Radio key={inx} value={m}>{m}</Radio>
                    ))}
                </Radio.Group>
            );
        } else if (q.questionTypeId === TypeId.MultiSelect) {
            return (
                <Select
                    mode="tags"
                    size={"large"}
                    style={{ width: '100%' }}
                    placeholder="Select an item or type something new and press enter"
                >
                    {q.options?.map((option: string, index: number) => (
                        <Select.Option key={index} value={option}>{option}</Select.Option>
                    ))}
                </Select>
            );
        } else if (q.questionTypeId === TypeId.Email) {
            return <Input type="email" className='element' />;
        } else if (q.questionTypeId === TypeId.Document) {
            return <Upload><Button icon={<UploadOutlined />}>Click to Upload</Button></Upload>;
        } else if (q.questionTypeId === TypeId.Number) {
            return <InputNumber style={{ width: '100%' }} />;
        } else {
            return <Input placeholder="Unsupported question type" />;
        }
    };

    const renderStandardField = (field: string) => {
        switch (field) {
            case "ID Number":
            case "Contact number":
            case "Current salary":
            case "Expected salary":
                return <InputNumber style={{ width: '100%' }} />;
            case "Upload CV":
                return <Upload><Button icon={<UploadOutlined />}>Click to Upload</Button></Upload>;
            case "POPI Act consent agreement":
                return <Checkbox>I agree to the POPI Act terms</Checkbox>;
            default:
                return <Input />;
        }
    };

    const confirmDeleteQuestion = async (e: any, q: ISurveyQuestion) => {
        try {
            await surveyQuestion.deleteSurveyQuestion(q);
            surveyQuestion.setSurveyQuestions(surveyQuestion.surveyQuestions.value.data.filter(item => item.id !== q.id));
            message.success("Question deleted");
        } catch (e: any) {
            console.log(e.message);
        }
    };

    const handleSelectSurvey = (index: number) => {
        setSelectedValue(index);
        surveyStore.selectSurvey(index);
    };

    const handleAddOptionalField = (field: string) => {
        if (!optionalFields.includes(field)) {
            setOptionalFields([...optionalFields, field]);
        }
    };

    const handleRemoveOptionalField = (field: string) => {
        setOptionalFields(optionalFields.filter(f => f !== field));
    };

    const optionalFieldsMenu = (
        <Menu>
            {OptionalFields.map((field, index) => (
                <Menu.Item key={index} onClick={() => handleAddOptionalField(field)}>
                    {field}
                </Menu.Item>
            ))}
        </Menu>
    );

    return (
        <div className="survey-root">
            <div className="question-type-list">
                <div className='add-question-text'>
                    Add question
                </div>
                {surveyQuestionType.hrQuestionTypes.value.data.map((type: ISurveyQuestionType, i: number) => (
                    <div key={i} onClick={() => addNewQuestion(type.id as TypeId)} className='q-type-contain'>
                        <div className='question-type-box'>
                            {getIconComponent(type.id as TypeId)}
                        </div>
                        <div className='type-name'>{type.name}</div>
                    </div>
                ))}
            </div>

            <div className="survey-section">
                <DragDropContext onDragEnd={sortQuestion}>
                    <Droppable droppableId="droppable-1" type="QUESTION">
                        {(provided, snapshot) => (
                            <div className='survey' {...provided.droppableProps} ref={provided.innerRef}>
                                <div className="optional-fields-dropdown">
                                    <Dropdown overlay={optionalFieldsMenu} trigger={['click']}>
                                        <Button icon={<PlusOutlined />}>
                                            Add Optional Field
                                        </Button>
                                    </Dropdown>
                                </div>
                                <div className='survey-controls'>
                                    <Select size={'large'} onChange={handleSelectSurvey} placeholder="Select Survey" className='survey-select' value={selectedValue}>
                                        {surveyText.map((surv, inx) => (
                                            <Option key={inx} value={inx}>{surv.name}</Option>
                                        ))}
                                    </Select>

                                    <QuestionModal question={currentQuestion} visible={questionModalVisible} setVisible={setQuestionModalVisible} />
                                </div>

                                <div className='welcome-closing-messsage'>
                                    <span className='opening-msg'>
                                        {selectedOpeningMsg}
                                    </span>
                                </div>

                                {/* Standard Fields */}
                                {StandardFields.map((field, index) => (
                                    <div key={`standard-${index}`} className='question'>
                                        <div className='question-top'>
                                            <span className='question-title'>{field}</span>
                                        </div>
                                        <div className="question-element">
                                            {renderStandardField(field)}
                                        </div>
                                    </div>
                                ))}

                                {/* Optional Fields */}
                                {optionalFields.map((field, index) => (
                                    <div key={`optional-${index}`} className='question'>
                                        <div className='question-top'>
                                            <span className='question-title'>{field}</span>
                                            <span className='question-controls'>
                                                <Buttons.Text
                                                    text='Remove'
                                                    onClick={() => handleRemoveOptionalField(field)}
                                                />
                                            </span>
                                        </div>
                                        <div className="question-element">
                                            <Input />
                                        </div>
                                    </div>
                                ))}

                                {/* Custom Questions */}
                                {surveyQuestion.surveyQuestions.value.data.map((q: ISurveyQuestion, i: number) => (
                                    <Draggable index={i} key={q.id + "-order"} draggableId={String(q.id) + "-order"}>
                                        {(provider) => (
                                            <div key={q.id + "-key"} {...provider.draggableProps} {...provider.dragHandleProps} ref={provider.innerRef} className='question'>
                                                <div className='question-top'>
                                                    {q.questionTypeId === TypeId.Header ?
                                                        <span className='question-title header'> {q.text}</span>
                                                        :
                                                        <span className='question-title'> {q.text}</span>
                                                    }
                                                    <span className='question-controls'>
                                                        <Buttons.Text
                                                            text='Edit'
                                                            onClick={() => showQuestionModal(q)}
                                                        />
                                                        <Popconfirm
                                                            title="Delete this question?"
                                                            onConfirm={(e) => confirmDeleteQuestion(e, q)}
                                                            okText="Yes"
                                                            cancelText="No"
                                                        >
                                                            <Buttons.Text text='Delete' />
                                                        </Popconfirm>
                                                    </span>
                                                </div>
                                                <div className="question-element">
                                                    {renderElement(q)}
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}

                                <div className='welcome-closing-messsage'>
                                    <span className='opening-msg'>
                                        {selectedClosingMsg}
                                    </span>
                                </div>

                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>


            </div>
        </div>
    );
});