import { Spin } from "antd";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Store } from "../../stores/stores";
import "./style.css"; 

const SurveyResponseDrawer = observer(() => {
    const { surveyResponseStore, surveyQuestion } = Store;
    const response = surveyResponseStore.currentSurveyResponse;
    const allQuestions = surveyQuestion.surveyQuestions.value.data;

    useEffect(() => {
        if (!allQuestions.length) {
            surveyQuestion.loadSurveyQuestions();
        }
    }, []);

    if (surveyQuestion.surveyQuestions.isLoading) {
        return <Spin size="large" />;
    }

    if (!response) {
        return <div>No response selected</div>;
    }

    const surveyQuestions = allQuestions.filter(q => q.surveyId === response.surveyId);
    const sortedQuestions = [...surveyQuestions].sort((a, b) => a.position - b.position);

    return (
        <div className="survey-response-drawer">
            {sortedQuestions.map((question, index) => {
                const answer = response.response.find(a => a.questionId === question.id);
                return (
                    <div key={question.id} className="survey-response-card">
                        <div className="survey-response-question">
                            Question {index + 1}: {question.text}
                        </div>
                        <textarea 
                            className="survey-response-answer"
                            value={(answer?.value || []).join(", ") || "No answer provided"}
                            readOnly 
                        />
                    </div>
                );
            })}

            <div className="survey-response-card">
                <div className="survey-response-question">Submitted At</div>
                <textarea 
                    className="survey-response-answer"
                    value={response.createdAt}
                    readOnly 
                />
                <div className="survey-response-submitted">
                    Response ID: {response.id}
                </div>
            </div>
        </div>
    );
});

export default SurveyResponseDrawer;