import { Button, Dropdown, Input, Table } from "antd";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { RiSearchLine } from "react-icons/ri";
import { Spinners } from "..";
import { Store } from "../../stores/stores";
import { ISurveyResponse } from "../../utils/interfaces";
import { TypeId } from "../../utils/enums";

interface SurveyResponsesTableProps {
    surveyId: string;
}

const SurveyResponsesTable: React.FC<SurveyResponsesTableProps> = observer(({ surveyId }) => {
    const { surveyResponseStore, surveyStore } = Store;
    const [filteredResponses, setFilteredResponses] = useState<ISurveyResponse[]>([]);

    const currentSurvey = surveyStore.hrSurveys.value.data.find(survey => survey.id === surveyId);
    const surveyName = currentSurvey ? currentSurvey.name : 'Unknown Survey';

    const getResponseValue = (response: ISurveyResponse, typeId: TypeId) => {
        const answer = response.response.find(r => r.questionTypeId === typeId);
        return answer ? answer.value : 'N/A';
    };

    const columns = [
        {
            title: "Name",
            key: "name",
            render: (text: any, record: ISurveyResponse) => getResponseValue(record, TypeId.Text),
        },
        {
            title: "Surname",
            key: "surname",
            render: (text: any, record: ISurveyResponse) => getResponseValue(record, TypeId.Text),
        },
        {
            title: "Email Address",
            key: "emailAddress",
            render: (text: any, record: ISurveyResponse) => getResponseValue(record, TypeId.Email),
        },
        {
            title: "View",
            key: "view",
            render: (_:any, record:ISurveyResponse) => (
                <Button onClick={() => handleViewResponse(record.id)}>
                    View
                </Button>
            ),
        },
    ];

    const handleViewResponse = (id: string) => {
        surveyResponseStore.loadSurveyResponse(id);
        surveyResponseStore.setShowResponseDrawer(true);
    };

    useEffect(() => {
        if (surveyResponseStore.surveyResponses.value.data.length === 0) {
            surveyResponseStore.loadSurveyResponses();
        }
    }, []);

    useEffect(() => {
        const filtered = surveyResponseStore.surveyResponses.value.data.filter(
            response => response.surveyId === surveyId
        );
        setFilteredResponses(filtered);
    }, [surveyId, surveyResponseStore.surveyResponses.value.data]);

    return (
        <Spinners.CustomSpinner
            title="Getting Survey Responses"
            isSpinning={surveyResponseStore.surveyResponses.isLoading}
        >
            <div className='pageheader-container'>
                <h1 className='header'>Responses for Survey: {surveyName}</h1>
                <div className='pageheader-options'>
                    <div className="pageheader-seperator">
                        <Dropdown
                            overlay={[] as any}
                            className='pageheader-options-categories'
                            disabled={true}
                        >
                            <Button className='dropdown-btn'>
                                Filters
                            </Button>
                        </Dropdown>
                    </div>

                    <Input
                        size='large'
                        style={{ margin: 0 }}
                        className='pageheader-options-search contract-pricing-search'
                        placeholder="Search list..."
                        prefix={<RiSearchLine color='#CBD5E0' />}
                        onChange={(e) => {
                            // Implement search functionality here
                        }}
                    />
                </div>
            </div>

            <Table
                rowKey='id'
                className='custom-table contractpricing-table'
                columns={columns}
                dataSource={filteredResponses}
                pagination={false}
            />
        </Spinners.CustomSpinner>
    );
});

export default SurveyResponsesTable;